/*
--------------------------------------------------------------------------------
CutShort
--------------------------------------------------------------------------------

<![CDATA[

*/
import {Utils} from './Utils';

;(function(window, $) {

    "use strict";

    var TOLERANCE = 2,
        MORE_CLASS = "more",
        LESS_CLASS = "less",
        DATA_MIN_HEIGHT = "minHeight",
        DATA_LINES_TO_SHOW = "data-lines-to-show",
        LINES_DEFAULT = 2,
        LINE_HEIGHT = 15,
        ELLIPSIS = "\u2026 ",
        ANIM_DURATION = 450,
        THROTTLING_DURATION = 500,

        self = ori.addModule("cutShort"),
        animationCounter,
        isInitRequested = false;

    function backgroundChange(element, background) {
        var image, imageElement;
        image = new Image();
        image.src = background;
        imageElement = element.find("img");
        image.onload = function() {
            imageElement.attr("src", image.src);
        };
    }

    function animateHeader($header, maxHeight, duration) {
        // animate to maxHeight
        $header.animate({ height: maxHeight },
            {
                duration: duration,
                progress: function() {
                    animationCounter++;
                    if (animationCounter > 1) {
                        stopHeaderAnimation($header, maxHeight);
                    }
                }
            }
        );
    }

    function stopHeaderAnimation($header, maxHeight) {
        var headerHeight = parseInt($header.height());
        if (headerHeight >= maxHeight + 1) {
            // stop animation
            $header.stop(true, false);
        }
    }

    function delayedCutShortInit() {
        if (!isInitRequested) {
            window.requestAnimationFrame(requestAnimationFrameCallback);
            isInitRequested = true;
        }
    }

    const throttledDelayedCutShortInit = Utils.throttle(() => delayedCutShortInit(), THROTTLING_DURATION);

    function requestAnimationFrameCallback() {
        isInitRequested = false;
        self.init();
    }

    self.append({

        isInitialized: false,
        options: {
            LinkClass: "highlighted-link"
        },

        // Initialization.
        // TODO : "standard" init() and self usage
        init: function(customOptions) {
            $.extend(self.options, customOptions);

            var $context = customOptions && customOptions.context ? $(customOptions.context) : $(document),
                $textNodes = $context.find(".js-cut-short"),
                $testNode = $('<span style="line-height:inherit;display:none;">&nbsp;</span>'),
                $more = $('<span class="' + MORE_CLASS + ' ' + self.options.LinkClass + '" />'),
                $less = $('<span class="' + LESS_CLASS + ' ' + self.options.LinkClass + '" />');

            // connect event handler - on new breakpoint reinit cutShort
            if (ori.device && !self.isInitialized) {
                // TODO: strange logic with calling init() again
                ori.device.bindMedia(throttledDelayedCutShortInit, self.ns);
                ori.device.bindResize(throttledDelayedCutShortInit, self.ns);

                // we want to disconnect device events applied on window
                // in this module's namespace, as ori.device will disconnect only
                // own namespace automatically
                self.disconnectOwnEventsOnWindowUnload();

                self.isInitialized = true;
            }
            $textNodes.each(function(index, el) {
                var $currentNode = $(el),
                    originalHtml = $currentNode.html(),
                    $parent = $currentNode,
                    $header = $parent,
                    maxHeight = null,
                    after = $currentNode.attr("data-after-selector"),
                    moreText = $currentNode.attr("data-show-more"),
                    lessText = $currentNode.attr("data-show-less"),
                    lines = LINES_DEFAULT,
                    dataLines,
                    lineHeight;

                $currentNode.append($testNode);

                if ($currentNode.attr(DATA_LINES_TO_SHOW) !== null) {
                    dataLines = $currentNode.attr(DATA_LINES_TO_SHOW);
                    try {
                        dataLines = JSON.parse(dataLines);
                    } catch (error) {
                        self.info("Data is not object", error);
                    }
                }

                if (typeof dataLines === "object" && dataLines !== null && ori && ori.device && ori.device.activeBreakpoint && typeof dataLines[ori.device.activeBreakpoint.index] !== "undefined") {
                    lines = dataLines[ori.device.activeBreakpoint.index];
                } else if (typeof dataLines === "number" && dataLines > 0) {
                    lines = dataLines;
                }

                lineHeight = $testNode.height() || LINE_HEIGHT;
                $testNode.detach();

                var options =
                    {
                        ellipsis: ELLIPSIS,
                        height: lines * lineHeight,
                        tolerance: TOLERANCE,
                        keep: after
                    };

                if (!after && moreText) {
                    options.callback = function() {
                        $more.clone().text(moreText);
                    };
                }

                var background = $currentNode.attr("data-parent-background");
                if (background) {
                    backgroundChange($currentNode.closest(".ui-category-header"), background);
                }

                $currentNode.bind("click.cutShort", "." + MORE_CLASS + ", ." + LESS_CLASS, function(e) {
                        var $trigger = $(e.target),
                            data = $parent.data(),
                            minHeight = $header.data(DATA_MIN_HEIGHT) || parseInt($header.height()),
                            content;

                        $header.data(DATA_MIN_HEIGHT, minHeight);

                        if ($trigger.hasClass(MORE_CLASS) || $trigger.hasClass(LESS_CLASS)) {
                            if (data.cutFull && $trigger.hasClass(MORE_CLASS)) {
                                content = data.cutFull;
                                $header.removeClass(MORE_CLASS).addClass(LESS_CLASS);
                                $parent.empty().append(content);
                                animationCounter = 0;
                                animateHeader($header, maxHeight, ANIM_DURATION);
                            }
                            else if (data.cutFull) {
                                content = data.cutShort;
                                $header.removeClass(LESS_CLASS).addClass(MORE_CLASS);
                                $parent.empty().append(content);
                                $header.animate({
                                    height: minHeight,
                                    duration: ANIM_DURATION
                                    });
                            }
                            else {
                                content = $parent.triggerHandler("originalContent").add($less.clone().text(lessText));
                                data.cutFull = content;
                                $header.removeClass(MORE_CLASS).addClass(LESS_CLASS);
                                $header.height(minHeight);
                                $parent.empty().append(content);
                                maxHeight = $header.prop("scrollHeight");
                                animationCounter = 0;
                                animateHeader($header, maxHeight, ANIM_DURATION);
                            }
                        }
                    })
                    .dotdotdot(options)
                    .data("cutShort", $currentNode.html());

                // for long words with no spaces, text is completely cut off - truncate by letter instead of word
                var pattern = new RegExp("^\\s*" + ELLIPSIS, "gi");
                if ($currentNode.text().match(pattern)) {
                    $currentNode.html(originalHtml);
                    options.truncate = "letter";
                    $currentNode.dotdotdot(options);
                }
            });
        }
    });

})(window, jQuery);
/* ]]> */
